import React, { useState, useEffect, useCallback, memo } from 'react';
import FeedbackForm from '../components/FeedbackForm';
import HeaderMenu from '../components/HeaderMenu';
import {WidgetPreviewApp} from '../components/preview/WidgetPreview';
import EmbedWidget from '../components/EmbedWidget';
import './WidgetPage.scss';
import apiServiceInstance from '../services/load';

const WidgetPageRender = memo(({config}) => (
    <WidgetPreviewApp 
    isPreviewMode={true}
    title={config.title}
    subtitle={config.subtitle}
    buttonText={config.buttonText}
    successMessage={config.successMessage}
    placeholderText={config.emailPlaceholder}
    handleSubmit={(e) => {
        e.preventDefault();
        return false;
    }}
    buttonCss={{
        backgroundColor: config.buttonColor,
    }}
    inputCss={{
        borderColor: config.buttonColor,
    }}
    titleCss={{
        color: config.titleColor,
    }}
    >
    </WidgetPreviewApp>
))

// Memoize the FormField component
const FormField = memo(({ name, label, type = "text", value, onChange, onBlur, error, touched }) => (
  <div className="mb-3">
    <label className="form-label">{label}</label>
    <input
      type={type}
      className={`form-control ${error && touched ? 'is-invalid' : ''} ${type === 'color' ? 'form-control-color' : ''}`}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    {error && touched && (
      <div className="invalid-feedback">
        {error}
      </div>
    )}
  </div>
));

const defaultConfig = {
  name: 'My Widget',
  title: 'Subscribe Now',
  subtitle: 'Join our newsletter',
  emailPlaceholder: 'Enter your email',
  buttonText: 'Subscribe',
  successMessage: 'Thank you for subscribing!',
  buttonColor: '#0d6efd',
  titleColor: '#000000'
};

const WidgetConfigurator = () => {
  const [widgetId, setWidgetId] = useState('');
  const [, setWidgetData] = useState(null);
  const [config, setConfig] = useState({});

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    console.log('useEffect called');
    async function fetchData() {
      try {
        const widgets = await apiServiceInstance.getUserWidgets();
        if (widgets.data.length) {
          setWidgetData(widgets.data[0]);
          setWidgetId(widgets.data[0].id);
          setConfig((configProps) => ({
            ...configProps,
            name: widgets.data[0].name,
            ...widgets.data[0].config,
          }));
        } else {
          setConfig(defaultConfig);
        }
      } catch(error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const validateField = useCallback((name, value) => {
    switch (name) {
      case 'name':
        return !value.trim() ? 'Widget name is required' : 
               value.length < 3 ? 'Widget name must be at least 3 characters' : '';
      case 'title':
        return !value.trim() ? 'Title is required' :
               value.length > 50 ? 'Title must be less than 50 characters' : '';
      case 'subtitle':
        return value.length > 100 ? 'Subtitle must be less than 100 characters' : '';
      case 'emailPlaceholder':
        return !value.trim() ? 'Email placeholder is required' : '';
      case 'buttonText':
        return !value.trim() ? 'Button text is required' :
               value.length > 20 ? 'Button text must be less than 20 characters' : '';
      case 'successMessage':
        return !value.trim() ? 'Success message is required' : '';
      case 'buttonColor':
        return !value.match(/^#[0-9A-F]{6}$/i) ? 'Invalid color format' : '';
      case 'titleColor':
        return !value.match(/^#[0-9A-F]{6}$/i) ? 'Invalid color format' : '';
      default:
        return '';
    }
  }, []);

  const handleBlur = useCallback((name) => {
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    
    const error = validateField(name, config[name]);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  }, [config, validateField]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    
    // Update config immediately
    setConfig(prev => ({
      ...prev,
      [name]: value
    }));

    // Delay validation to next tick to prevent cursor jump
    if (touched[name]) {
      requestAnimationFrame(() => {
        const error = validateField(name, value);
        setErrors(prev => ({
          ...prev,
          [name]: error
        }));
      });
    }
  }, [touched, validateField]);

  const handleSave = useCallback(() => {
    const isValid = Object.keys(config).every(field => !validateField(field, config[field]));
    
    if (!isValid) {
      const allTouched = Object.keys(config).reduce((acc, field) => ({
        ...acc,
        [field]: true
      }), {});
      setTouched(allTouched);
      
      const newErrors = {};
      Object.keys(config).forEach(field => {
        const error = validateField(field, config[field]);
        if (error) {
          newErrors[field] = error;
        }
      });
      setErrors(newErrors);
      
      alert('Please fix the validation errors before saving.');
      return;
    }

    const data = {};
    data['name'] = config.name;
    data['config'] = {
      ...config,
    };
    delete data['config'].name;
    if (widgetId) {
      apiServiceInstance.updateUserWidget(widgetId, data).then((response) => {
        setWidgetId(response.id);
        setWidgetData(response);
        alert('Widget saved');
      });
    } else {
      apiServiceInstance.createUserWidget(data).then((response) => {
        setWidgetId(response.id);
        setWidgetData(response);
        alert('Widget saved');
      });
    }
  }, [config, validateField, widgetId]);

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Widget Configuration</h5>
            </div>
            <div className="card-body">
              <form>
                {Object.entries(config).map(([name, value]) => (
                  <FormField
                    key={name}
                    name={name}
                    label={name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, ' $1')}
                    type={name.includes('Color') ? 'color' : 'text'}
                    value={value}
                    onChange={handleChange}
                    onBlur={() => handleBlur(name)}
                    error={errors[name]}
                    touched={touched[name]}
                  />
                ))}

                <button 
                  type="button" 
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save Widget Configuration
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="mb-0">Widget ID</h5>
            </div>
            <div className="card-body">
                <input
                  type="text"
                  className="form-control"
                  value={widgetId}
                  readOnly
                />
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="mb-0">Widget Preview</h5>
            </div>
            <div className="card-body">
              <WidgetPageRender config={config} />
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Widget Code</h5>
            </div>
            <div className="card-body">
              <EmbedWidget widgetId={widgetId}></EmbedWidget>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function WidgetPage() {
    return (
        <div className="lead-stack-wait-list-container">
            <HeaderMenu></HeaderMenu>
            <div>
                <WidgetConfigurator></WidgetConfigurator>
            </div>
            <FeedbackForm />
        </div>
    );
}
