import './WidgetPreview.scss';
import { useState } from 'react';

export function WidgetPreviewApp(props = {
    isPreviewMode: false,
    containerId: 'wait-list',
    widgetId: 'wait-list',
    title: 'Join Our Wait List',
    subtitle: 'Be the first to know when we launch new features!',
    buttonText: 'Join Wait List',
    successMessage: 'Thank you for joining our wait list!',
    placeholderText: 'Enter your email',
    handleSubmit: undefined,
    apiService: undefined,
    buttonCss: undefined,
    titleCss: undefined,
    inputCss: undefined,
}) {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(props.successMessage || '');
    const [submitError, setSubmitError] = useState('');

    const handleSubmit = props.handleSubmit ? props.handleSubmit : async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage('');
        setSubmitError('');

        try {
            await new props.apiService.saveEmail(props.widgetId, { email });
            setSubmitMessage(props.successMessage);
            setEmail('');
        } catch (error) {
            console.error('Error submitting to wait list:', error);
            setSubmitError(
                error.response?.data?.error ||
                    'An error occurred. Please try again.'
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const priorityTitleStyles = {
        ...props.titleCss
    };
    const priorityButtonStyles = {
        ...props.buttonCss,
    };
    const priorityInputStyles = {
        ...props.inputCss,
    };

    return (
        <div id={`${props.containerId}_container`} className='wait-list'>
            <h2 style={{...priorityTitleStyles}}>{props.title}</h2>
            <p>{props.subtitle}</p>
            <form onSubmit={handleSubmit}>
                <input
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={props.placeholderText}
                    required
                    style={{...priorityInputStyles}}
                />
                <button type='submit' style={{...priorityButtonStyles}} disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : props.buttonText}
                </button>
            </form>
            <div className={'message-container'}>
                {(submitMessage || props.isPreviewMode) && (
                    <p className='success-message'>{submitMessage}</p>
                )}
                {submitError && <p className='error-message'>{submitError}</p>}
            </div>
			<div className={'powered-by'}><a target='_blank' rel="noreferrer" href={'https://www.getleadstack.com'}>Powered By <strong>GetLeadStack.com</strong></a></div>
        </div>
    );
}