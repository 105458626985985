import React from 'react';
import FeedbackForm from '../components/FeedbackForm';
import HeaderMenu from '../components/HeaderMenu';
import './PrivacyPolicyPage.scss';

export default function PrivacyPolicyPage() {
    return (
        <div className="lead-stack-wait-list-container">
            <HeaderMenu></HeaderMenu>
            <div className='container policy-container'>
                <div className='row border-0'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div>
                            <div>
                                <div>
                                    <p>Updated at 2024-10-28</p>
                                    <p style={{ display: 'inline' }}>
                                        {' '}
                                        MatteCurve Technologies Pvt. Ltd.
                                    </p>
                                    &nbsp;
                                    <p
                                        className='p4'
                                        style={{ display: 'inline' }}
                                    >
                                        <span>
                                            (&lsquo;we&lsquo;,
                                            &lsquo;our&lsquo;, or
                                            &lsquo;us&lsquo;) is committed to
                                            protecting your privacy. This
                                            Privacy Policy explains how we
                                            collect, use, and disclose your
                                            personal information. This Policy
                                            applies to our website,
                                            https://www.getleadstack.com, and its
                                            associated subdomains (collectively,
                                            our “Service”). By accessing or
                                            using our Service, you agree to our
                                            collection, use, storage, and
                                            disclosure of your personal
                                            information as described in this
                                            Privacy Policy.
                                        </span>
                                    </p>
                                    <br />
                                    <br />
                                    <h2>Definitions and Key Terms</h2>
                                    <p>
                                        <span>
                                            For this Privacy Policy:
                                        </span>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>
                                                <strong>Cookie:</strong> A small
                                                piece of data generated by a
                                                website and saved by your web
                                                browser, used to identify your
                                                browser, provide analytics, and
                                                remember information such as
                                                language preference or login
                                                information.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Company:</strong> Refers
                                                to MatteCurve Technologies Pvt.
                                                Ltd., Udaipur, Rajasthan, India,
                                                responsible for your information
                                                under this Privacy Policy.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Country:</strong> Where
                                                getleadstack.com or its
                                                owners/founders are based, in
                                                this case, India.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Customer:</strong>{' '}
                                                Refers to the entity or person
                                                that signs up to use the
                                                getleadstack.com Service.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Device:</strong> Any
                                                internet-connected device such
                                                as a phone, tablet, or computer
                                                used to visit getleadstack.com.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>IP address:</strong> A
                                                number assigned to a device
                                                connected to the Internet to
                                                identify the location from which
                                                the device is connecting.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Personnel:</strong>{' '}
                                                Individuals who are employed by
                                                getleadstack.com or are under
                                                contract to perform a service.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Personal Data:</strong>{' '}
                                                Information that directly or
                                                indirectly allows the
                                                identification of a natural
                                                person.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Service:</strong> The
                                                service provided by getleadstack.com
                                                as described in this document
                                                and on our platform.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>
                                                    Third-party service:
                                                </strong>{' '}
                                                Advertisers, contest sponsors,
                                                promotional and marketing
                                                partners, and others who provide
                                                our content or whose products or
                                                services may interest you.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>Website:</strong>{' '}
                                                getleadstack.com’s site, accessible
                                                via https://www.getleadstack.com.
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <strong>You:</strong> The person
                                                or entity registered with
                                                getleadstack.com to use the
                                                Services.
                                            </span>
                                        </li>
                                    </ul>
                                    <div>
                                        <h2>Information We Collect</h2>
                                        <p>
                                            We collect information from you when
                                            you:
                                        </p>
                                        <ul>
                                            <li>Visit our service</li>
                                            <li>Register</li>
                                            <li>Place an order</li>
                                            <li>Subscribe to our newsletter</li>
                                            <li>
                                                Respond to a survey or fill out
                                                a form
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h2>Types of Information Collected</h2>
                                        <ul>
                                            <li>
                                                <span className='s3'>
                                                    Company Name
                                                </span>
                                            </li>

                                            <li>
                                                <span className='s3'>
                                                    First Name, Last Name
                                                </span>
                                            </li>

                                            <li>
                                                <span className='s3'>
                                                    Email Address
                                                </span>
                                            </li>

                                            <li>
                                                <span className='s3'>
                                                    Password
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <h2>How We Use the Information</h2>
                                    <p>
                                        <span>
                                            We may use the information collected
                                            from you in the following ways:
                                        </span>
                                    </p>
                                    <ul>
                                        <li>
                                            To personalize your experience
                                        </li>
                                        <li>
                                            To improve our service
                                        </li>
                                        <li>
                                            To improve customer service
                                        </li>
                                        <li>
                                            To process transactions
                                        </li>
                                        <li>
                                            To administer a contest, promotion,
                                            survey, or other site feature
                                        </li>
                                        <li>
                                            To send periodic emails
                                        </li>
                                    </ul>
                                    <h2>Information from Third Parties</h2>
                                    <p>
                                        We may receive some information from
                                        third parties such as automated fraud
                                        detection services and publicly
                                        available social media information.
                                    </p>
                                    <h2>
                                        Sharing Information with Third Parties
                                    </h2>
                                    <p>
                                        We may share information, both personal
                                        and non-personal, with:
                                    </p>
                                    <ul>
                                        <li>
                                            Advertisers, contest sponsors,
                                            promotional and marketing partners,
                                            and content providers.
                                        </li>
                                        <li>
                                            Affiliated companies and business
                                            partners.
                                        </li>
                                        <li>
                                            Third-party service providers for
                                            services such as hosting, database
                                            management, email management, and
                                            credit card processing.
                                        </li>
                                        <li>
                                            Government or law enforcement
                                            officials or private parties as
                                            needed to respond to claims, legal
                                            processes, or to protect our rights,
                                            property, or safety.
                                        </li>
                                    </ul>
                                    <h2>Email Use</h2>
                                    <p>
                                        By submitting your email address, you
                                        agree to receive emails from us. You can
                                        opt-out at any time via the unsubscribe
                                        link in each email.
                                    </p>
                                    <h2>International Information Transfer</h2>
                                    <p>
                                        Information collected may be transferred
                                        to various locations around the world.
                                        By using our service, you consent to
                                        such transfers.
                                    </p>
                                    <h2>Data Security</h2>
                                    <p>
                                        We implement security measures to
                                        protect your information though no
                                        system can be 100% secure. If a data
                                        breach occurs, we will take appropriate
                                        action.
                                    </p>
                                    <h2>Updating Your Information</h2>
                                    <p>
                                        You may update or correct your
                                        information by contacting us. Note that
                                        some information may persist in backup
                                        copies.
                                    </p>
                                    <h2>Business Transfer</h2>
                                    <p>
                                        In the event of a sale or transfer of
                                        our business, we reserve the right to
                                        transfer your information to the new
                                        owner.
                                    </p>
                                    <h2>Corporate Affiliates</h2>
                                    <p>
                                        We may disclose your information to our
                                        Corporate Affiliates, who will adhere to
                                        this Privacy Policy.
                                    </p>
                                    <h2>Information Retention</h2>
                                    <p>
                                        We retain your information only as long
                                        as necessary to provide our services and
                                        fulfill our legal obligations.
                                    </p>
                                    <h2>Children&lsquo;s Privacy</h2>
                                    <p>
                                        We do not knowingly collect personal
                                        information from children under the age
                                        of 13.
                                    </p>
                                    <h2>Changes to This Policy</h2>
                                    <p>
                                        We may update our Privacy Policy
                                        periodically and will post the updates
                                        on this page.
                                    </p>
                                    <h2>Contact Us</h2>
                                    <p>
                                    Don’t hesitate to contact us if you have any questions. Email: onkar@mattecurve.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackForm />
        </div>
    );
}
