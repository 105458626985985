import React, { useEffect, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import SessionService from '../services/session';

console.log(config);

// Create a context for analytics
const AnalyticsContext = createContext();

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

function AnalyticsTracker({ children }) {
  const location = useLocation();

  // Function to get the current user ID (you'll need to implement this based on your auth system)
  const getCurrentUserId = () => {
    // Implement your logic to get the current user ID
    // For example, you might get it from localStorage or a global state management system
    return SessionService.getUserId() || null;
  };

  // Track page view
  const trackPageView = async (path) => {
    try {
      await axios.post(`${config.apiUrl}/analytics/pageview`, {
        path,
        userId: getCurrentUserId(),
        referrer: document.referrer
      });
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  };

  // Track event
  const trackEvent = async (category, action, label, value) => {
    try {
      await axios.post(`${config.apiUrl}/analytics/event`, {
        category,
        action,
        label,
        value,
        userId: getCurrentUserId()
      });
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  // Track page view on component mount and route change
  useEffect(() => {
    trackPageView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export default AnalyticsTracker;
