import SessionService from './../services/session';
import config from '../config';
import { BiSolidUserAccount } from "react-icons/bi";
import { useAnalytics } from './AnalyticsTracker';

function HeaderMenu() {
    const { trackEvent } = useAnalytics();

    const handleGoogleSignIn = () => {
        // Implement your Google sign-in logic here
        console.log('Signing in with Google...');
        // eslint-disable-next-line no-undef
        trackEvent('HeaderMenu', 'GoogleSignIn', 'GoogleSignIn', '');
        window.location.href = `${config.apiUrl}/google/auth?redirect=true`;
    };

    const handleLogout = () => {
        // Implement your logout logic here
        console.log('Logging out...');
        trackEvent('UserPage', 'LogoutClick', 'LogoutClick', '');
        SessionService.logout();
        window.location.href = '/';
      };

    return (
        <div className="header-menu">
            <div className="logo">
                <a href='/'>
                    <img src='/logo.png' alt='LeadStack Logo'></img>
                </a>
            </div>
            {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
            <div>
                <a href='/widget/configure' className='link-underline-light me-2'>Widget</a>
                <a href='/widget/emails' className='link-underline-light ms-2 me-2'>Emails</a>
                <a href='/user' className='link-underline-light ms-2'>Account</a>
            </div>
            )}
            {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
            <div>
                <a href='#demo' className='link-underline-light me-2'>Demo</a>
                <a href='#pricing' className='link-underline-light ms-2 me-2'>Pricing</a>
                <a href='#faqs' className='link-underline-light ms-2'>FAQs</a>
            </div>
            )}
            {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
                <div>
                    <button className="header-button sing-in-button" onClick={() => handleGoogleSignIn()}>
                        Start using LeadStack
                    </button>
                    <button type='button' className="header-button-icon btn btn-outline-primary" onClick={() => handleGoogleSignIn()}><BiSolidUserAccount /></button>
                </div>
            )}
            {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
                <div>
                    <button className="header-button sing-in-button" onClick={() => handleLogout()}>
                        Logout
                    </button>
                    <button type='button' className="header-button-icon btn btn-outline-primary" onClick={() => window.location.href = '/user'}><BiSolidUserAccount /></button>
                </div>
            )}
        </div>
    );
}

export default HeaderMenu;