import axios from 'axios';
import SessionService from './session';

class ApiService {
  baseURL = '';

  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async authByGoogleCode(code) {
    try {
        const response = await this.api.get(`/google/redirect?code=${code}`);
        return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  getAuthenticatedRequestConfig() {
    const config = {};
    config.headers = {};
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = ['Bearer', SessionService.getUserAuthToken()].join(' ');
    return config;
  }

  async getUserProfile() {
    try {
        const userId = SessionService.getUserId();
        const requestConfig = this.getAuthenticatedRequestConfig();
        const response = await this.api.get(`/user/${userId}`, requestConfig);
        return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  async getUserWidgets() {
    try {
      const userId = SessionService.getUserId();
      const requestConfig = this.getAuthenticatedRequestConfig();
      const response = await this.api.get(`/user/${userId}/widget`, requestConfig);
      return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  async createUserWidget(data) {
    try {
      const userId = SessionService.getUserId();
      const requestConfig = this.getAuthenticatedRequestConfig();
      const response = await this.api.post(`/user/${userId}/widget`, data, requestConfig);
      return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  async updateUserWidget(widgetId, data) {
    try {
      const userId = SessionService.getUserId();
      const requestConfig = this.getAuthenticatedRequestConfig();
      const response = await this.api.patch(`/user/${userId}/widget/${widgetId}`, data, requestConfig);
      return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  async getUserWidgetEmails(widgetId, offset, limit) {
    try {
      const userId = SessionService.getUserId();
      const requestConfig = this.getAuthenticatedRequestConfig();
      const response = await this.api.get(`/user/${userId}/widget/${widgetId}/email?offset=${offset}&limit=${limit}`, requestConfig);
      return response.data;
    } catch (error) {
        this.handleError(error);
    }
  }

  async getFileDownloadUrl(widgetId) {
    try {
      const userId = SessionService.getUserId();
      const requestConfig = this.getAuthenticatedRequestConfig();
      const response = await this.api.get(`/user/${userId}/widget/${widgetId}/email?export=true`, requestConfig);
      return `${this.baseURL}/${response.data.url}`;
    } catch (error) {
        this.handleError(error);
    }
  }

  // Error handling
  handleError(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Error Response:', error.response.data);
      console.error('Error Status:', error.response.status);
      console.error('Error Headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error Request:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error Message:', error.message);
    }
    throw error; // Rethrow the error for further handling if needed
  }
}

export default ApiService;