// package_waitlist/src/pages/WidgetPage.js
import React from 'react';

const EmbedWidget = ({ widgetId = '669f8200f9d747515440da8b'}) => {
    // The widget code to be copied
    const widgetCode = `const callbackForLeadStackWidget = () => {
    const targetDivId1 = 'lead_stack_widget';
    const targetWidgetId1 = '${widgetId}';
    if (!document.getElementById(targetDivId1).children.length) {
        new window.LeadStackWidget(targetWidgetId1, 'full', (e, data) => {
            console.log(e, data);
        }, {}).show(targetDivId1);
    }

    // you can add render same or multiple widgets following above approach
};

// this should be called once
(function loadLeadStackWidget(callback) {
    var loaded = false;
    const scriptElem = document.createElement('script');
    scriptElem.type = 'text/javascript';
    scriptElem.src = 'https://www.getleadstack.com/widget.js';
    scriptElem.onload = function () {
        if (!loaded) {
            loaded = true;
            if (window.LeadStackWidget) {
                callback();
            }
        }
    };
    const existingElem = document.getElementsByTagName('script')[0];
    existingElem.parentNode?.insertBefore(scriptElem, existingElem);
})(callbackForLeadStackWidget);`;

    // Function to copy the widget code to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(widgetCode)
            .then(() => {
                alert('Widget code copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <div className="card-body">
            {/* <h5 className="card-title">Embed LeadStack Widget</h5> */}
            <p className="card-text">
                Copy the code below to embed the LeadStack widget on your website:
            </p>
            <textarea
                readOnly
                value={widgetCode}
                rows={4}
                style={{ width: '100%', fontFamily: 'monospace' }}
            />
            <button onClick={copyToClipboard} className="btn btn-primary mt-2">
                Copy Widget Code
            </button>
            <h6 className="mt-4">Instructions to Use the Embed Code</h6>
            <ol>
                <li><strong>Add a div:</strong> Add a div with id=lead_stack_widget to your website's HTML page where you want to render this widget.</li>
                <li><strong>Copy the Embed Code:</strong> Click the "Copy Widget Code" button above. This will copy the embed code to your clipboard.</li>
                <li><strong>Paste the Embed Code:</strong> Paste the Embed Code to script tag into your HTML.</li>
                <li><strong>Save Your Changes:</strong> After pasting the code, save the changes to your HTML file.</li>
                <li><strong>Upload Your HTML File:</strong> If you are working on a local file, upload the updated HTML file to your web server.</li>
                <li><strong>Test the Widget:</strong> Open your web browser and navigate to the page where you embedded the LeadStack widget to verify it appears correctly.</li>
                <li><strong>Adjust as Necessary:</strong> If you need to customize the appearance or functionality of the widget, refer to the LeadStack documentation for additional options.</li>
            </ol>
            <h6>Embedding in React</h6>
            <p>
                Add the above widget code in useEffect hook.
            </p>
            <h6>Embedding in Angular</h6>
            <p>
                Add the above widget code in ngOnInit.
            </p>
        </div>
    );
};

export default EmbedWidget;