import React, { useEffect, useState } from 'react';
import apiServiceInstance from '../services/load';

const EmailsList = () => {
    const [widgetId, setWidgetId] = useState('');
    const [emails, setEmails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [emailsPerPage] = useState(2);
    const [totalEmails, setTotalEmails] = useState(0);

    useEffect(() => {
        const fetchEmails = async (offset) => {
            try {
                const widgets = await apiServiceInstance.getUserWidgets();
                if (widgets.data.length) {
                    const response = await apiServiceInstance.getUserWidgetEmails(widgets.data[0].id, offset, emailsPerPage);
                    setEmails(response.data);
                    setTotalEmails(response.pagination.total);
                    setWidgetId(widgets.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching emails:', error);
            }
        };

        console.log(currentPage);
        const offset = currentPage <= 1 ? 0 : (currentPage - 1) * emailsPerPage;
        fetchEmails(offset);
    }, [emailsPerPage, currentPage]);

    const handleExport = async () => {
        try {
            const url = await apiServiceInstance.getFileDownloadUrl(widgetId);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'emails.csv'); // Specify the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error exporting emails:', error);
        }
    };

    const totalPages = Math.ceil(totalEmails / emailsPerPage);

    return (
        <div className="emails-page">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ margin: 0 }}>Emails</h2>
                <button onClick={handleExport} className="btn btn-primary mt-3">
                    Export Emails
                </button>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Email</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {emails.map((email, index) => (
                        <tr key={index}>
                            <td>{(currentPage - 1) * emailsPerPage + index + 1}</td>
                            <td>{email.email}</td>
                            <td>{new Date(email.createdAt).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button 
                    className='btn btn-link'
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span style={{ margin: '0 10px' }}> Page {currentPage} of {totalPages} </span>
                <button 
                    className='btn btn-link'
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default EmailsList;