import React, { useState } from 'react';
import './Pricing.scss';
import { FaCheckCircle } from 'react-icons/fa';

const LeadStackPricing = () => {
  const [emailsCollected, setEmailsCollected] = useState(0);

  const calculatePrice = () => {
    const freeEmails = 50;
    const pricePerThousand = 19;

    if (emailsCollected <= freeEmails) {
      return 0;
    } else {
      const extraEmails = emailsCollected - freeEmails;
      const thousandsCollected = Math.ceil(extraEmails / 1000);
      return thousandsCollected * pricePerThousand;
    }
  };

  const price = calculatePrice();

  return (
    <>
    <h3 className="text-2xl font-bold mb-0 text-center">Pricing</h3>
    <p className="hover-tip text-muted small fst-italic text-center mb-4 mt-0">
    Only pay when you collect emails. No monthly fees.
    </p>
    <div className="bg-white rounded shadow p-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <p className="m-0 fw-medium">First 50 emails</p>
          <p className="text-secondary small">Free</p>
        </div>
        <FaCheckCircle className="text-success h-6 w-6" />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <p className="m-0 fw-medium">Additional emails</p>
          <p className="text-secondary small">$19 per 1,000 emails</p>
        </div>
        <p className="text-dark font-weight-medium">
          {emailsCollected > 50 ? emailsCollected - 50 : 0} / 1,000
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <p className="m-0 fw-medium">Total</p>
        </div>
        <p className="text-dark font-weight-medium">${price.toFixed(2)}</p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="emails-collected" className="m-0 fw-medium">
            Emails Collected:
        </label>
        <input
          id="emails-collected"
          type="number"
          min="0"
          value={emailsCollected}
          onChange={(e) => setEmailsCollected(parseInt(e.target.value))}
          className="border rounded px-2 py-1 text-dark w-24"
        />
      </div>
    </div>
    </>
  );
};

export default LeadStackPricing;