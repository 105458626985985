const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    analyticsId: process.env.REACT_APP_ANALYTICS_ID,
    demoWidgetId: process.env.REACT_APP_DEMO_WIDGET_ID,
    paddle: {
        env: process.env.REACT_APP_PADDLE_ENVIRONMENT,
        token: process.env.REACT_APP_PADDLE_TOKEN,
        price1: process.env.REACT_APP_PADDLE_PRICE_1,
        price2: process.env.REACT_APP_PADDLE_PRICE_2,
    }
};
  
export default config;