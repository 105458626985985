import React from 'react';
import FeedbackForm from '../components/FeedbackForm';
import HeaderMenu from '../components/HeaderMenu';
import './TermsPage.scss';

export default function TermsPage() {
    return (
        <div className="lead-stack-wait-list-container">
            <HeaderMenu></HeaderMenu>
            <div className='container terms-container'>
                <div className='row border-0'>
                    <h1>Terms & Conditions</h1>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div>
                            <div>
                                <div>
                                    <p>Updated at 2024-10-28</p>
                                    <h2>General Terms</h2>
                                    <p>
                                        By accessing and placing an order with
                                        getleadstack.com, you confirm that you are
                                        in agreement with and bound by the terms
                                        of service contained in these Terms &
                                        Conditions. These terms apply to the
                                        entire website and any email or other
                                        type of communication between you and
                                        getleadstack.com. Under no circumstances
                                        shall the getleadstack.com team be liable
                                        for any direct, indirect, special,
                                        incidental, or consequential damages,
                                        including, but not limited to, loss of
                                        data or profit, arising out of the use,
                                        or the inability to use, the materials
                                        on this site, even if the getleadstack.com
                                        team or an authorized representative has
                                        been advised of the possibility of such
                                        damages.
                                        <br />
                                        <br />
                                        If your use of materials from this site
                                        results in the need for servicing,
                                        repair, or correction of equipment or
                                        data, you assume any costs thereof.
                                        getleadstack.com will not be responsible for
                                        any outcome that may occur during the
                                        course of using our resources. We
                                        reserve the right to change prices and
                                        revise the resource usage policy without
                                        notice.
                                    </p>
                                    <h2>License</h2>
                                    <p>
                                        MatteCurve Technologies Pvt. Ltd. grants
                                        you a revocable, non-exclusive,
                                        non-transferable, limited license to
                                        download, install, and use our service
                                        strictly in accordance with the terms of
                                        this Agreement. These Terms & Conditions
                                        constitute a contract between you and
                                        MatteCurve Technologies Pvt. Ltd.
                                        (referred to in these Terms & Conditions
                                        as “MatteCurve Technologies Pvt. Ltd.”,
                                        “us”, “we”, or “our”), the provider of
                                        the getleadstack.com website and the
                                        services accessible from the
                                        getleadstack.com website (collectively
                                        referred to in these Terms & Conditions
                                        as the “MatteCurve Technologies Pvt.
                                        Ltd. Service”).
                                        <br />
                                        <br />
                                        You agree to be bound by these Terms &
                                        Conditions. If you do not agree to these
                                        Terms & Conditions, please do not use
                                        the Service. In these Terms &
                                        Conditions, “you” refers both to you as
                                        an individual and to the entity you
                                        represent. If you violate any of these
                                        Terms & Conditions, we reserve the right
                                        to cancel your account or block access
                                        to your account without notice.
                                    </p>
                                    <h2>Definitions and Key Terms</h2>
                                    <ul>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Cookie:</strong> A small
                                                piece of data generated by a
                                                website and saved by your web
                                                browser, used to identify your
                                                browser, provide analytics, and
                                                remember information such as
                                                language preference or login
                                                information.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Company:</strong> Refers
                                                to MatteCurve Technologies Pvt.
                                                Ltd., Udaipur, Rajasthan, India,
                                                responsible for your information
                                                under this Privacy Policy.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Country:</strong> Where
                                                getleadstack.com or its
                                                owners/founders are based, in
                                                this case, India.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Customer:</strong>{' '}
                                                Refers to the entity or person
                                                that signs up to use the
                                                getleadstack.com Service.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Device:</strong> Any
                                                internet-connected device such
                                                as a phone, tablet, or computer
                                                used to visit getleadstack.com.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>IP address:</strong> A
                                                number assigned to a device
                                                connected to the Internet to
                                                identify the location from which
                                                the device is connecting.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Personnel:</strong>{' '}
                                                Individuals who are employed by
                                                getleadstack.com or are under
                                                contract to perform a service.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Personal Data:</strong>{' '}
                                                Information that directly or
                                                indirectly allows the
                                                identification of a natural
                                                person.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Service:</strong> The
                                                service provided by getleadstack.com
                                                as described in this document
                                                and on our platform.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>
                                                    Third-party service:
                                                </strong>{' '}
                                                Advertisers, contest sponsors,
                                                promotional and marketing
                                                partners, and others who provide
                                                our content or whose products or
                                                services may interest you.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>Website:</strong>{' '}
                                                getleadstack.com’s site, accessible
                                                via https://www.getleadstack.com.
                                            </span>
                                        </li>
                                        <li className='li8'>
                                            <span className='s1'>
                                                <strong>You:</strong> The person
                                                or entity registered with
                                                getleadstack.com to use the
                                                Services.
                                            </span>
                                        </li>
                                    </ul>
                                    <h2>Restrictions</h2>
                                    <p>
                                        <span>
                                            You agree not to, and will not
                                            permit others to:
                                        </span>
                                    </p>
                                    <ul>
                                        <li>
                                            License, sell, rent, lease, assign,
                                            distribute, transmit, host,
                                            outsource, disclose, or commercially
                                            exploit the service or make the
                                            platform available to any third
                                            party.
                                        </li>
                                        <li>
                                            Modify, make derivative works of,
                                            disassemble, decrypt, reverse
                                            compile or reverse engineer any part
                                            of the service.
                                        </li>
                                        <li>
                                            Remove, alter, or obscure any
                                            proprietary notice (including any
                                            notice of copyright or trademark) of
                                            or its affiliates, partners,
                                            suppliers, or licensors of the
                                            service.
                                        </li>
                                    </ul>
                                    <h2>Payment</h2>
                                    <p>
                                        If you register for any of our recurring
                                        payment plans, you agree to pay all fees
                                        or charges to your account for the
                                        Service following the fees, charges, and
                                        billing terms in effect at the time each
                                        fee or charge is due and payable. Unless
                                        otherwise indicated in an order form,
                                        you must provide a valid credit card
                                        (Visa, MasterCard, or any other issuer
                                        we accept) (“Payment Provider”) as a
                                        condition to signing up for the Premium
                                        plan.
                                        <br />
                                        <br />
                                        Your Payment Provider agreement governs
                                        your use of the designated credit card
                                        account, and you must refer to that
                                        agreement and not these Terms to
                                        determine your rights and liabilities
                                        with your Payment Provider. By providing
                                        your credit card number and associated
                                        payment information, you agree that we
                                        are authorized to verify information
                                        immediately and subsequently invoice
                                        your account for all fees and charges
                                        due and payable to us hereunder and that
                                        no additional notice or consent is
                                        required.
                                        <br />
                                        <br />
                                        You agree to notify us immediately of
                                        any change in your billing address or
                                        the credit card used for payment
                                        hereunder. We reserve the right to
                                        change prices and billing methods at any
                                        time, either immediately upon posting on
                                        our Site or via email to your
                                        organization’s administrator(s). Any
                                        attorney fees, court costs, or other
                                        costs incurred in collection of
                                        delinquent undisputed amounts shall be
                                        paid for by you. No contract will exist
                                        between you and us until we accept your
                                        order by a confirmatory email, SMS/MMS
                                        message, or other appropriate
                                        communication. You are responsible for
                                        any third-party fees incurred when using
                                        the Service.
                                    </p>
                                    <h2>Return and Refund Policy</h2>
                                    <p>
                                        Thanks for shopping with us. We
                                        appreciate your interest in our
                                        products. We want to ensure you have a
                                        rewarding experience while exploring,
                                        evaluating, and purchasing our products.
                                        As with any shopping experience, there
                                        are terms and conditions that apply to
                                        transactions at our company. By placing
                                        an order or making a purchase from us,
                                        you agree to the terms along with our
                                        Privacy Policy. If, for any reason, you
                                        are not completely satisfied with any
                                        good or service that we provide, please
                                        contact us to discuss any issues you are
                                        experiencing with our product.
                                    </p>
                                    <h2>Your Suggestions</h2>
                                    <p>
                                        Any feedback, comments, ideas,
                                        improvements, or suggestions
                                        (collectively, “Suggestions”) provided
                                        by you to us regarding the service shall
                                        remain our sole and exclusive property.
                                        We are free to use, copy, modify,
                                        publish, or redistribute the Suggestions
                                        for any purpose and in any way without
                                        credit or compensation to you.
                                    </p>
                                    <h2>Your Consent</h2>
                                    <p>
                                        We have updated our Terms & Conditions
                                        to provide transparency into what is
                                        being set when you visit our site and
                                        how it’s being used. By using our
                                        service, registering an account, or
                                        making a purchase, you consent to our
                                        Terms & Conditions.
                                    </p>
                                    <h2>Links to Other Websites</h2>
                                    <p>
                                        Our service may contain links to other
                                        websites not operated by us. If you
                                        click on a third-party link, you will be
                                        directed to that third-party’s site. We
                                        strongly advise you to review the Terms
                                        & Conditions of every site you visit. We
                                        have no control over and assume no
                                        responsibility for the content, Terms &
                                        Conditions, or practices of any
                                        third-party sites or services.
                                    </p>
                                    <h2>Cookies</h2>
                                    <p>
                                        We use cookies to identify the areas of
                                        our website that you have visited. A
                                        cookie is a small piece of data stored
                                        on your computer or mobile device by
                                        your web browser. We use cookies to
                                        enhance the performance and
                                        functionality of our service but are
                                        non-essential to their use. However, if
                                        you disable cookies, you may not be able
                                        to access functionality on our website
                                        correctly or at all.
                                    </p>
                                    <h2>Changes To Our Terms & Conditions</h2>
                                    <p>
                                        We may stop (permanently or temporarily)
                                        providing the Service (or any features
                                        within the Service) to you or users
                                        generally at our sole discretion,
                                        without prior notice. You may stop using
                                        the Service at any time without
                                        notifying us specifically. You
                                        acknowledge and agree that if we disable
                                        access to your account, you may be
                                        prevented from accessing the Service,
                                        your account details, or any files or
                                        other materials in your account. Changes
                                        to our Terms & Conditions will be posted
                                        on this page, and/or we will update the
                                        Terms & Conditions modification date
                                        below.
                                    </p>
                                    <h2>Modifications to Our service</h2>
                                    <p>
                                        We reserve the right to modify, suspend,
                                        or discontinue, temporarily or
                                        permanently, the service or any service
                                        to which it connects, with or without
                                        notice and without liability to you.
                                    </p>
                                    <h2>Updates to Our service</h2>
                                    <p>
                                        We may provide enhancements or
                                        improvements to the
                                        features/functionality of the service,
                                        which may include patches, bug fixes,
                                        updates, upgrades, and other
                                        modifications (“Updates”). Updates may
                                        modify or delete certain features and/or
                                        functionalities of the service. You
                                        agree that we have no obligation to (i)
                                        provide any Updates or (ii) continue to
                                        provide or enable any particular
                                        features and/or functionalities of the
                                        service to you. All Updates will be
                                        deemed to constitute an integral part of
                                        the service, and will be subject to
                                        these Terms & Conditions.
                                    </p>
                                    <h2>Third-Party Services</h2>
                                    <p>
                                        We may display, include, or make
                                        available third-party content (including
                                        data, information, applications, and
                                        other products/services) or provide
                                        links to third-party websites or
                                        services (“Third-Party Services”). You
                                        acknowledge and agree that we shall not
                                        be responsible for any Third-Party
                                        Services, including their accuracy,
                                        completeness, timeliness, validity,
                                        copyright compliance, legality, decency,
                                        quality, or any other aspect thereof.
                                        Third-Party Services and links thereto
                                        are provided solely as a convenience to
                                        you and are accessed and used entirely
                                        at your own risk and subject to such
                                        third parties’ terms and conditions.
                                    </p>
                                    <h2>Term and Termination</h2>
                                    <p>
                                        This Agreement shall remain in effect
                                        until terminated by you or us. We may,
                                        in our sole discretion, terminate this
                                        Agreement anytime and for any or no
                                        reason, with or without prior notice.
                                        This Agreement will terminate
                                        immediately, without prior notice, if
                                        you fail to comply with any provision of
                                        this Agreement. Upon termination, you
                                        must cease all use of the service and
                                        delete all copies of the service from
                                        your computer. Termination will not
                                        limit any of our rights or remedies at
                                        law or in equity in case of breach by
                                        you (during the term of this Agreement)
                                        of any of your obligations under this
                                        Agreement.
                                    </p>
                                    <h2>Copyright Infringement Notice</h2>
                                    <p>
                                        If you are a copyright owner or such
                                        owner’s agent and believe any material
                                        from us constitutes an infringement on
                                        your copyright, please contact us
                                        providing the following information: (a)
                                        a physical or electronic signature of
                                        the copyright owner or a person
                                        authorized to act on his behalf; (b)
                                        identification of the material that is
                                        claimed to be infringing; (c) your
                                        contact information, including your
                                        address, telephone number, and an email;
                                        (d) a statement by you that you have a
                                        good faith belief that the use of the
                                        material is not authorized by the
                                        copyright owners; and (e) a statement
                                        that the information in the notification
                                        is accurate, and, under penalty of
                                        perjury, you are authorized to act on
                                        behalf of the owner.
                                    </p>
                                    <h2>Indemnification</h2>
                                    <p>
                                        You agree to indemnify and hold us and
                                        our subsidiaries, affiliates, officers,
                                        employees, agents, partners, and
                                        licensors (if any) harmless from any
                                        claim or demand, including reasonable
                                        attorneys’ fees, due to or arising out
                                        of your: (a) use of the service; (b)
                                        violation of this Agreement or any law
                                        or regulation; or (c) violation of any
                                        right of a third party.
                                    </p>
                                    <h2>No Warranties</h2>
                                    <p>
                                        The service is provided to you “AS IS”
                                        and “AS AVAILABLE” and with all faults
                                        and defects without warranty of any
                                        kind. To the maximum extent permitted by
                                        applicable law, we, on our behalf and on
                                        behalf of our affiliates and our
                                        licensors and service providers,
                                        expressly disclaim all warranties,
                                        express, implied, statutory, or
                                        otherwise, with respect to the service,
                                        including all implied warranties of
                                        merchantability, fitness for a
                                        particular purpose, title, and
                                        non-infringement. We make no
                                        representation that the service will
                                        meet your requirements, achieve any
                                        intended results, be compatible or work
                                        with any other software, websites,
                                        systems, or services, operate without
                                        interruption, meet any performance or
                                        reliability standards, or be error-free.
                                    </p>
                                    <h2>Limitation of Liability</h2>
                                    <p>
                                        Notwithstanding any damages that you
                                        might incur, the entire liability of us
                                        and any of our suppliers under any
                                        provision of this Agreement and your
                                        exclusive remedy for all of the
                                        foregoing shall be limited to the amount
                                        actually paid by you for the service. To
                                        the maximum extent permitted by
                                        applicable law, in no event shall we or
                                        our suppliers be liable for any special,
                                        incidental, indirect, or consequential
                                        damages whatsoever, even if we or any
                                        supplier has been advised of the
                                        possibility of such damages.
                                    </p>
                                    <h2>Severability</h2>
                                    <p>
                                        If any provision of this Agreement is
                                        held to be unenforceable or invalid,
                                        such provision will be changed and
                                        interpreted to accomplish the objectives
                                        of such provision to the greatest extent
                                        possible under applicable law, and the
                                        remaining provisions will continue in
                                        full force and effect.
                                    </p>
                                    <h2>Waiver</h2>
                                    <p>
                                        No failure to exercise, and no delay in
                                        exercising, on the part of either party,
                                        any right or power under this Agreement
                                        shall operate as a waiver of that right
                                        or power. Neither shall any single or
                                        partial exercise of any right under this
                                        Agreement preclude further exercise of
                                        that or any other right granted herein.
                                    </p>
                                    <h2>Amendments to this Agreement</h2>
                                    <p>
                                        We reserve the right to modify or
                                        replace this Agreement at any time. If a
                                        revision is significant, we will provide
                                        at least 30 days’ notice before the new
                                        terms take effect. What constitutes a
                                        &ldquo;material change&ldquo; will be
                                        determined at our sole discretion. By
                                        continuing to access or use our service
                                        after any revisions become effective,
                                        you agree to be bound by the revised
                                        terms. If you do not agree to the new
                                        terms, please stop using the service.
                                    </p>
                                    <h2>Entire Agreement</h2>
                                    <p>
                                        This Agreement constitutes the entire
                                        agreement between you and us regarding
                                        your use of the service and supersedes
                                        all prior and contemporaneous
                                        agreements. You may be subject to
                                        additional terms and conditions that
                                        apply when you use or purchase other
                                        services from us.
                                    </p>
                                    <h2>Intellectual Property</h2>
                                    <p>
                                        Our platform and its entire contents,
                                        features, and functionality (including
                                        but not limited to all information,
                                        software, text, displays, images, video,
                                        and audio, and the design, selection,
                                        and arrangement thereof) are owned by
                                        us, our licensors, or other providers of
                                        such material and are protected by and
                                        international copyright, trademark,
                                        patent, trade secret, and other
                                        intellectual property laws. The material
                                        may not be copied, modified, reproduced,
                                        downloaded, or distributed in any way
                                        without prior written permission from
                                        us.
                                    </p>
                                    <h2>Agreement to Arbitrate</h2>
                                    <p>
                                        This section applies to any dispute
                                        except for disputes related to claims
                                        for injunctive or equitable relief
                                        regarding the enforcement or validity of
                                        your or our intellectual property
                                        rights. The term “dispute” means any
                                        dispute, action, or other controversy
                                        between you and us concerning the
                                        services or this agreement.
                                    </p>
                                    <ul>
                                        <li>
                                            Notice of Dispute: In the event of a
                                            dispute, you or us must give the
                                            other a written Notice of Dispute,
                                            which includes the party’s name,
                                            contact information, the facts
                                            giving rise to the dispute, and the
                                            relief requested. We will attempt to
                                            resolve the dispute through informal
                                            negotiation within 60 days. If not
                                            resolved within 60 days, either
                                            party may commence arbitration.
                                        </li>
                                        <li>
                                            Binding Arbitration: If the dispute
                                            is not resolved through informal
                                            negotiation, it will be resolved
                                            exclusively by binding arbitration.
                                            The dispute shall be settled by
                                            binding arbitration in accordance
                                            with the commercial arbitration
                                            rules of the American Arbitration
                                            Association. Any interim or
                                            preliminary injunctive relief may be
                                            sought from a court of competent
                                            jurisdiction, as necessary to
                                            protect a party’s rights or property
                                            pending completion of arbitration.
                                        </li>
                                    </ul>
                                    <h2>Submissions and Privacy</h2>
                                    <p>
                                        Any ideas, suggestions, designs,
                                        photographs, information, or other
                                        materials you submit will be treated as
                                        non-confidential and non-proprietary,
                                        becoming our sole property.
                                    </p>
                                    <h2>Promotions</h2>
                                    <p>
                                        We may offer contests, promotions, or
                                        other activities
                                        (&ldquo;Promotions&ldquo;) that require
                                        you to submit material or information
                                        about yourself. All Promotions will have
                                        separate rules that may contain
                                        eligibility requirements. By
                                        participating in any Promotion, you
                                        agree to comply with all Promotions
                                        rules.
                                    </p>
                                    <h2>Typographical Errors</h2>
                                    <p>
                                        If a product/service is listed at an
                                        incorrect price or with incorrect
                                        information due to typographical error,
                                        we reserve the right to refuse or cancel
                                        any orders placed for the
                                        product/service.
                                    </p>
                                    <h2>Miscellaneous</h2>
                                    <p>
                                        If any provision of these Terms &
                                        Conditions is found to be unenforceable,
                                        the remainder will continue in full
                                        force and effect. Any waiver must be in
                                        writing and signed by an authorized
                                        representative of us. These Terms &
                                        Conditions, along with our Privacy
                                        Policy, contain the entire understanding
                                        between you and us and supersede all
                                        prior agreements.
                                    </p>
                                    <h2>Disclaimer</h2>
                                    <p>
                                        We are not responsible for any content,
                                        code, or any other inaccuracies. We do
                                        not provide warranties or guarantees. In
                                        no event shall we be liable for any
                                        special, direct, indirect,
                                        consequential, or incidental damages
                                        arising out of or in connection with the
                                        use of the service.
                                    </p>
                                    <h2>Contact Us</h2>
                                    <p>
                                        Don’t hesitate to contact us if you have
                                        any questions. Email:
                                        onkar@mattecurve.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackForm />
        </div>
    );
}
