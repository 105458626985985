import React, { useState, useEffect } from 'react';
import FeedbackForm from '../components/FeedbackForm';
import './UserPage.scss';
import HeaderMenu from '../components/HeaderMenu';
import FooterSection from '../components/Footer';
import { FaShoppingCart } from 'react-icons/fa';
import apiServiceInstance from './../services/load';
import UserProfileDataService from './../services/user';
import SessionService from './../services/session';
import config from '../config';
import { useAnalytics } from './../components/AnalyticsTracker'; 

function UserPage() {
  const { trackEvent } = useAnalytics();
  const [, setFormData] = useState({});
  const [, setIsEditing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [creditBalance, setCreditBalance] = useState(0);
  const [email, setEmail] = useState('');
  const [purchaseCredit, setPurchaseCredit] = useState(false);

  // Callback to open a checkout
  const openCheckout = (priceId) => {
    window.Paddle?.Checkout.open({
      items: [{ priceId, quantity: 1 }],
      customData: {
        referenceId: SessionService.getUserId(),
      },
    });
  };

  const checkoutPrice1 = (e) => {
    e.preventDefault();
    trackEvent('UserPage', 'PurchaseAttempt', 'PurchaseAttempt', config.paddle.price1);
    // openCheckout(config.paddle.price1);
    alert('We are working on it, we will make it live soon. Thanks!')
  };
  const checkoutPrice2 = (e) => {
    e.preventDefault();
    trackEvent('UserPage', 'PurchaseAttempt', 'PurchaseAttempt', config.paddle.price1);
    // openCheckout(config.paddle.price2);
    alert('We are working on it, we will make it live soon. Thanks!')
  };

  function reloadFormData() {
    if (SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) {
      const userProfile = UserProfileDataService.getUserProfile();
      if (userProfile.healthProfiles && userProfile.healthProfiles.length) {
        setFormData(prevData => ({
          ...prevData,
          ...userProfile.healthProfiles[0]
        }));
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }
    } else {
      const savedData = localStorage.getItem('foodPurchaseData');
      console.log('savedData',savedData)
      if (savedData) {
        setFormData(prevData => ({
          ...prevData,
          ...JSON.parse(savedData)
        }));
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }
    }
  }

  useEffect(() => {
    // Simulate fetching user login status and credit balance
    const auth = localStorage.getItem('auth');
    if (auth && SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) {
      setIsLoggedIn(true);
      apiServiceInstance.getUserProfile().then((userProfile) => {
        console.log(userProfile);
        UserProfileDataService.saveUserProfile(userProfile);
        setCreditBalance(UserProfileDataService.getUserAvailableCredits()); 
        setEmail(UserProfileDataService.getUserEmail());
        reloadFormData();
      });
      // step 1 - load profile
      // step 2 - load credit
      // step 3 - load health profile
      const paddleConfig = { 
        token: config.paddle.token,
        eventCallback: (data) => {
          console.log(data);
          if (data.name === "checkout.completed") {
            // delay webhook update
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      };
      window.Paddle.Environment.set(config.paddle.env);
      window.Paddle.Setup(paddleConfig);
    } else {
      setIsLoggedIn(false);
      reloadFormData();
    }
  }, []);

  const handlePurchaseCredits = () => {
    // Implement your purchase credits logic here
    console.log('Purchasing credits...');
    trackEvent('UserPage', 'PurchaseCreditClick', 'PurchaseCreditClick', '');
    setPurchaseCredit(true);
  };

  const handleGoogleSignIn = () => {
    // Implement your Google sign-in logic here
    console.log('Signing in with Google...');
    trackEvent('UserPage', 'GoogleSignIn', 'GoogleSignIn', '');
    window.location.href = `${config.apiUrl}/google/auth?redirect=true`;
  };

  return (
    <div className="lead-stack-wait-list-container">
      <HeaderMenu></HeaderMenu>
      <div className="account-section mt-4">
        <div className="card">
          <div className="card-body">
           {isLoggedIn ? <h5 className="card-title">Account Section</h5> : ''}
            {isLoggedIn ? (
            <div className="user-actions">
              <p className="mt-2 mb-1">Your Email: <strong>{email}</strong></p>
              <p className="">Email Credits: <strong>{creditBalance}</strong></p>
              <button 
                className="btn btn-primary purchase-button" 
                onClick={handlePurchaseCredits} 
                title="Purchase credits for premium features"
              >
                <FaShoppingCart className="me-2" /> {/* Add an icon */}
                Purchase Credits
              </button>
              {purchaseCredit && (
                <div className='border-1 mt-2 mb-4'>
                  <div className='mb-2'> $19 = 1000 Credits - <button type='button' className='btn-link' onClick={(e) => checkoutPrice1(e)}> Buy now </button> </div>
                </div>
                )}
            </div>
            ) : (
              <div>

                <div className='text-center'>
                <button className="btn btn-outline-primary mt-2 sing-in-button" onClick={handleGoogleSignIn}>
            Sign In / Sign up with Google
          </button>
                </div>
          </div>
        )}
          </div>
        </div>
      </div>

      {isLoggedIn ? 

      <div className="account-section mt-4">
        <div className="card">
          <div className="card-body">
        <p>Payments are handled securely via <b>Paddle.com</b>.</p>
        <p>Charges will appear on your bill as <b>LeadStack</b> and you will receive invoices from <b>Mattecurve Technologies Private Limited</b>.</p>
        <p>If you have any questions regarding billing / purchases, please contact us at <b>onkar&#64;mattecurve.com</b>.</p>
        </div>
        </div>
      </div>
      : ''}

      <div className='text-center'>
        <FeedbackForm isFixed={false} customText='Would you mind sharing your feedback?' />
      </div>
      
      <FeedbackForm />
      <FooterSection></FooterSection>
    </div>
  );
}

export default UserPage;
