import React, { useState, useEffect } from 'react';
import FeedbackForm from '../components/FeedbackForm';
import './HomePage.scss';
import HeaderMenu from '../components/HeaderMenu';
import FooterSection from '../components/Footer';
import config from '../config';
import { useAnalytics } from '../components/AnalyticsTracker'; 
import { FaUsers, FaArrowCircleRight } from 'react-icons/fa';
import SessionService from '../services/session';
import LeadStackPricing from '../components/Pricing';

function HomePage() {
  const { trackEvent } = useAnalytics();
  const [, setFormData] = useState({});
  const [, setIsEditing] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  useEffect(() => {
    const savedData = localStorage.getItem('foodPurchaseData');
    if (savedData) {
      setFormData(prevData => ({
        ...prevData,
        ...JSON.parse(savedData)
      }));
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, []);

  const faqs = [
    {
      question: "What is LeadStack?",
      answer: "LeadStack is a streamlined waitlist tool designed specifically for founders who want to focus on collecting emails without unnecessary features. It helps you build your audience efficiently and effectively."
    },
    {
      question: "How does LeadStack work?",
      answer: "LeadStack allows you to create a simple and effective waitlist form that you can embed on your website or share via social media. As users sign up, their email addresses are collected, allowing you to engage with them when your product is ready."
    },
    {
      question: "Are there any monthly fees with LeadStack?",
      answer: "No, LeadStack does not charge any monthly fees. You only pay for the emails you collect, making it a cost-effective solution for building your waitlist."
    },
    {
      question: "What types of businesses can benefit from LeadStack?",
      answer: "LeadStack is ideal for startups, founders, and businesses of all sizes looking to build a waitlist for their upcoming products or services. It’s particularly useful for those who want a straightforward solution without the complexity of additional features."
    },
    {
      question: "How can I track my email collection progress?",
      answer: "LeadStack provides a simple dashboard where you can monitor the number of emails collected in real-time. This allows you to track your progress and adjust your marketing strategies as needed."
    },
    {
      question: "Can I customize the waitlist form?",
      answer: "Yes, LeadStack allows you to customize your waitlist form to match your brand’s look and feel. You can adjust colors, text, and other elements to create a cohesive experience for your users."
    },
    {
      question: "What support options are available if I need help?",
      answer: "LeadStack provides support via email (onkar@mattecurve.com) and video (https://calendly.com/onkarjanwa/30-minute-meeting?month=2024-11)."
    },
  ];

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const handleGoogleSignIn = () => {
    // Implement your Google sign-in logic here
    console.log('Signing in with Google...');
    trackEvent('HomePage', 'GoogleSignIn', 'GoogleSignIn', '');
    setTimeout(() => {
      window.location.href = `${config.apiUrl}/google/auth?redirect=true`;
    });
  };

  const userCount = 25;

  useEffect(() => {
      const iframeUrl = '/widget.js';
      const callbackForRender = () => {
        if (!document.getElementById('wait_list_render').children.length) {
          new window.LeadStackWidget(config.demoWidgetId, 'full', (e, data) => {
            console.log(e, data);
          }, {}).show('wait_list_render');
        }
      };
  
      (function loadScript(callback) {
          console.log('loadScript called');
          var loaded = false;
          const scriptElem = document.createElement('script');
          scriptElem.type = 'text/javascript';
          scriptElem.src = iframeUrl;
          scriptElem.onload = function () {
            if (!loaded) {
                loaded = true;
                if (window.LeadStackWidget) {
                    callback();
                }
            }
        };
          const existingElem = document.getElementsByTagName('script')[0];
          existingElem.parentNode?.insertBefore(scriptElem, existingElem);
      })(callbackForRender);
  }, []);
  
  return (
    <div className="lead-stack-wait-list-container">
      <HeaderMenu></HeaderMenu>
      <section className='page-container m-auto'>
        <div className="header">
          <h1 className="title">Waitlist emails. Nothing else.</h1>
          <p className="subtitle">
          The stripped-down waitlist tool for founders  <span> who want results, not features. </span>
          </p>
          {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
          <button onClick={() => handleGoogleSignIn()} className="sing-in-button mt-5">
              <span>Sign In / Sign up with Google</span>
          </button>
          )}
          {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
            <button className="sing-in-button mt-5" onClick={() => window.location.href = '/user'}>
            Access your account 
            </button>
          )}
          <button onClick={() => window.location.href = '#demo'} className="secondary-button mt-5 ms-2">
              <span>See demo</span> <FaArrowCircleRight className='ms-2'></FaArrowCircleRight>
          </button>
          <p className="hover-tip text-muted small fst-italic text-center mb-0 mt-4">
            No monthly fees - No cost to join - Pay per email collected
          </p>
          <div className="user-count text-center mt-4">
            <FaUsers className="user-icon" />
            <span className="user-count-text">
            Join {userCount}+ happy users!
            </span>
        </div>
        </div>
      </section>
      <section className="page-container m-auto companies-using mt-5 mb-5 pt-5 pb-5">
          <h2 className='h4 mb-4 text-dark'>Businesses that trust LeadStack</h2>
          <div className="company-list">
              <div className="company-item">
                  <img src="./customers/foodfitai.png" alt="FoodFitAi" />
              </div>
              <div className="company-item">
                  <img src="./customers/datarisy.png" alt="DataRisy" />
              </div>
              <div className="company-item">
                  <img src="./customers/theremotework.svg" alt="TheRemoteWork" />
              </div>
          </div>
      </section>
      <section className='page-container m-auto' id='demo'>
        <div className="how-to-use gradient-bg">
          <h2 className='h4 mb-4 text-dark'>Demo</h2>
          <div className="steps-container">
            <div id='wait_list_render' className='w-100' style={{minHeight: '345px', height: '361px'}}></div>
          </div>
        </div>
      </section>
      <section className="page-container m-auto mt-5 mb-5" id='pricing'>
        <div className='m-auto' style={{maxWidth: '400px'}}><LeadStackPricing></LeadStackPricing></div>
        <div className='text-center'>
        {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
          <button onClick={() => handleGoogleSignIn()} className="sing-in-button mt-5">
              <span>Sign In / Sign up with Google</span>
          </button>
          )}
          {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
            <button className="sing-in-button mt-5" onClick={() => window.location.href = '/user'}>
            Access your account 
            </button>
          )}
        </div>
      </section>
      <section className="page-container m-auto faqs" id='faqs'>
        <h2>Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${openFaqIndex === index ? 'open' : ''}`}>
            <button className="faq-question" onClick={() => toggleFaq(index)}>
              {faq.question}
              <span className="faq-icon">{openFaqIndex === index ? '−' : '+'}</span>
            </button>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </section>
      <section className='page-container m-auto mt-5'><FooterSection></FooterSection></section>
      <FeedbackForm />
    </div>
  );
}

export default HomePage;