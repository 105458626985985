import React from 'react';
import FeedbackForm from '../components/FeedbackForm';
import HeaderMenu from '../components/HeaderMenu';
import EmailsList from '../components/EmailsList';
import './EmailsPage.scss';

export default function EmailsPage() {
    return (
        <div className="lead-stack-wait-list-container">
            <HeaderMenu></HeaderMenu>
            <div>
                <EmailsList></EmailsList>
            </div>
            <FeedbackForm />
        </div>
    );
}
